import React from 'react';

import Icon from './Icon';

export default function ViewsIcon({
  className = '',
  text = 'Views',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66459 0.32918C7.87574 0.223607 8.12426 0.223607 8.33541 0.32918L14.3354 3.32918C14.5895 3.45622 14.75 3.71592 14.75 4C14.75 4.28408 14.5895 4.54378 14.3354 4.67082L8.33541 7.67082C8.12426 7.77639 7.87574 7.77639 7.66459 7.67082L1.66459 4.67082C1.4105 4.54378 1.25 4.28408 1.25 4C1.25 3.71592 1.4105 3.45622 1.66459 3.32918L7.66459 0.32918ZM3.67705 4L8 6.16147L12.3229 4L8 1.83853L3.67705 4Z"
          fill="currentColor"
        />
        <path
          d="M1.32916 11.6646C1.5144 11.2941 1.96491 11.1439 2.33539 11.3292L7.99998 14.1615L13.6646 11.3292C14.0351 11.1439 14.4856 11.2941 14.6708 11.6646C14.856 12.0351 14.7059 12.4856 14.3354 12.6708L8.33539 15.6708C8.12425 15.7764 7.87572 15.7764 7.66457 15.6708L1.66457 12.6708C1.29409 12.4856 1.14392 12.0351 1.32916 11.6646Z"
          fill="currentColor"
        />
        <path
          d="M2.33539 7.32916C1.96491 7.14392 1.5144 7.29409 1.32916 7.66457C1.14392 8.03505 1.29409 8.48556 1.66457 8.6708L7.66457 11.6708C7.87572 11.7764 8.12425 11.7764 8.33539 11.6708L14.3354 8.6708C14.7059 8.48556 14.856 8.03505 14.6708 7.66457C14.4856 7.29409 14.0351 7.14392 13.6646 7.32916L7.99998 10.1615L2.33539 7.32916Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
